import { StudentApplication } from '../types'

enum TimelineTabs {
  PERSONAL_INFORMATION = 1,
  CONTACT_INFORMATION = 2,
  STATUS_AND_CITIZENSHIP = 3,
  EDUCATION_HISTORY = 4,
  LANGUAGE_PROFICIENCY = 5,
  OTHER_REQUIRED_DOCUMENTS = 6,
}

type isTabCompletedProps = {
  index: number
  application: StudentApplication
}

export function firstTabIncomplete(app: StudentApplication): number {
  const tabs = Object.values(TimelineTabs)
    .map(itm => +itm)
    .filter(v => !isNaN(v))

  return (
    tabs.find(
      tab => !isTabCompleted({ application: app, index: tab - 1 }) || tab === tabs[tabs.length - 1],
    ) ?? 1
  )
}

export function isTabCompleted(props: isTabCompletedProps) {
  // If we're planning to support DRAFT in the future, we can copy this function from student
  // For now, it's true always
  return true
}
