import { Flex, Text } from '@applyboard/crystal-ui'
import { formatAsDocumentDate } from '../../../utils/formatAsDocumentDate'
import { DocumentTags } from '../../../utils/enums'
import { parseISO } from 'date-fns'
import { LanguageExamHistoryRecord } from '../ApplicationDocuments'

export function LanguageExamHistoryEntryFields({ record }: { record: LanguageExamHistoryRecord }) {
  const referenceLabel = record.tags.includes(DocumentTags.LANGUAGE_TEST_IELTS)
    ? 'TRF Number'
    : record.tags.includes(DocumentTags.LANGUAGE_TEST_TOEFL)
      ? 'Appointment number'
      : record.tags.includes(DocumentTags.LANGUAGE_TEST_PTE)
        ? 'Score report code'
        : 'Reference number'

  return (
    <Flex direction="column" gap={12} pb={4}>
      <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Flex direction="column">
            <Text variant="labelS">Test Date</Text>
            <Text variant="bodyS">
              {record.context.testDate
                ? formatAsDocumentDate(parseISO(record.context.testDate))
                : '---'}
            </Text>
          </Flex>
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Flex direction="column">
            <Text variant="labelS">{referenceLabel}</Text>
            <Text variant="bodyS">{record.context.certificateNumber || '---'}</Text>
          </Flex>
        </Flex.Item>
      </Flex>
      <Flex direction="column" gap={3}>
        <Flex gap={4} direction="row" wrap>
          <Flex.Item basis={'calc(50% - 8px)'}>
            <Flex direction="column">
              <Text variant="labelS">Overall Score</Text>
              <Text variant="bodyS">{record.context.overallScore || '---'}</Text>
            </Flex>
          </Flex.Item>
        </Flex>
        <Flex gap={4} direction="row" wrap>
          <Flex.Item basis={'calc(50% - 8px)'}>
            <Flex direction="column">
              <Text variant="labelS">Listening</Text>
              <Text variant="bodyS">{record.context.subscores?.listening || '---'}</Text>
            </Flex>
          </Flex.Item>
          <Flex.Item basis={'calc(50% - 8px)'}>
            <Flex direction="column">
              <Text variant="labelS">Reading</Text>
              <Text variant="bodyS">{record.context.subscores?.reading || '---'}</Text>
            </Flex>
          </Flex.Item>
        </Flex>
        <Flex gap={4} direction="row" wrap>
          <Flex.Item basis={'calc(50% - 8px)'}>
            <Flex direction="column">
              <Text variant="labelS">Writing</Text>
              <Text variant="bodyS">{record.context.subscores?.writing || '---'}</Text>
            </Flex>
          </Flex.Item>
          <Flex.Item basis={'calc(50% - 8px)'}>
            <Flex direction="column">
              <Text variant="labelS">Speaking</Text>
              <Text variant="bodyS">{record.context.subscores?.speaking || '---'}</Text>
            </Flex>
          </Flex.Item>
        </Flex>
      </Flex>
    </Flex>
  )
}
