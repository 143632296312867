/**
 * Formats a timeless date string, using the current user's local timezone
 *
 * @param str The date string to format (e.g. 2023-01-01)
 * @returns A formatted date
 */
export function convertTimelessDateStrToLocalDate(str: string) {
  const [year, month, day] = str.split('-')
  return new Date(Number(year), Number(month) - 1, Number(day), 12, 0, 0, 0)
}
