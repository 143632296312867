import { Flex, Heading } from "@applyboard/crystal-ui";
import { ApplicationsPageProps, GetApplicationsQuerys, useGetApplicationsPageProps, useGetApplicationsQueryDefinitions,  } from "../../hooks";
import { NavBars } from '../../components/NavBars'
import { ApplicationsList } from "./ApplicationsList/ApplicationsList";
import { PagePropsContextProvider } from "../../context/PagePropsContext";
import { QueryParameterContextProvider } from "../../context";
import { ApplicationFilters } from "./ApplicationsFilters";
import { Loading } from "../../components/Loading";

export function ApplicationsListPage() {
  return (
    <NavBars largePage>
      <ApplicationListPageContext>
        <Flex gap={3} direction="column">
          <Flex justify="between" align="center" direction="row">
            <Heading level={1} variant="headlineL">
              Applications
            </Heading>
          </Flex>
          <ApplicationFilters />
          <ApplicationsList />
        </Flex>
      </ApplicationListPageContext>
    </NavBars>
  )
}

type ApplicationListPageContextProps = {
  readonly children: React.ReactNode
}

function ApplicationListPageContext({ children }: ApplicationListPageContextProps) {
  const { pageProps, isFetchingApplications, isLoadingApplications } = useGetApplicationsPageProps()
  const queryDefinitions = useGetApplicationsQueryDefinitions()

  if (isFetchingApplications || isLoadingApplications) {
    return <Loading />
  }

  if(!pageProps) return null

  return (
    <PagePropsContextProvider<ApplicationsPageProps> pageProps={pageProps}>
      {/* @ts-ignore */}
      <QueryParameterContextProvider<GetApplicationsQuerys>
        initialQueryParameters={{
          sort: '-submittedAt',
          pageSize: '20',
          pageNumber: '1',
        }}
        queryDefinitions={queryDefinitions}
      >
        {children}
      </QueryParameterContextProvider>
    </PagePropsContextProvider>
  )
}
