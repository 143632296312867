import { format } from 'date-fns'
import { Flex, Text, createForm } from '@applyboard/crystal-ui'
import { IAdditionalDocumentDialog, IDocumentDialogForm } from '../types'
import { documentTypeOptions } from '../../../../utils'
import { EducationLevelLabels } from '../../../../utils/enums'
import { FileUploadField } from '../../ApplicationForms/FileUploadField'
import { DocumentDialog } from '../DocumentDialog'
import { useState } from 'react'
import { useUpdateRequestedDocuments } from '../../../../hooks'
import { countries } from '../../../../utils/countries'
import { convertTimelessDateStrToLocalDate } from '../../../../utils/convertTimelessDateStrToLocalDate'
import { useApplicationFormContext } from '../../ApplicationForms/ApplicationForm'
import { Asterisk } from '../../../Asterisk'

type DocumentsFormFields = {
  document: Array<{
    id: string
    file: File
  }>
}

const { Form, Field, useFieldValues, useSetFieldValues } = createForm<DocumentsFormFields>()

export function EducationHistoryDocumentsDialog(props: IAdditionalDocumentDialog) {
  const [open, setOpen] = useState(false)
  const { pendingFileUploadState, resetFiles } = useApplicationFormContext()
  const { isUpdatingApplication, updateRequestedDocuments } = useUpdateRequestedDocuments({
    applicationId: props.application.id,
    index: props.index,
  })

  const educationHistory = props.document.sectionReference
    ? props.application.attributes?.educationHistory?.[props.document.sectionReference]
    : null
  const requestedDocuments = props.application.attributes?.requestedDocuments?.[props.id]

  if (!educationHistory || !requestedDocuments) {
    return null
  }

  return (
    <DocumentDialog
      document={props.document}
      heading={`Add ${documentTypeOptions[props.document.documentType].label}`}
      form={`education-history-documents-${props.id}`}
      loading={isUpdatingApplication}
      open={open}
      setOpen={open => {
        resetFiles({})
        setOpen(open)
      }}
    >
      <Flex direction="column" gap={4}>
        <Text contrast="mid">{props.document.note}</Text>
        <Form
          defaultValues={{
            document: [],
          }}
          onSubmit={data => {
            updateRequestedDocuments({
              dataFiles: pendingFileUploadState,
              documentType: props.document.documentType,
              onSuccess: () => {
                setOpen(false)
              },
              requestedDocuments,
              requestedDocumentsId: props.id,
            })
          }}
          id={`education-history-documents-${props.id}`}
        >
          <Flex direction="column" gap={8} pb={4}>
            <Flex direction={{ xs: 'column', sm: 'row' }} gap={8}>
              <Flex direction="column" gap={2} basis={'calc(60% - 32px)'}>
                <Text variant="labelM">Education Level</Text>
                <Text>
                  {educationHistory.educationLevel
                    ? EducationLevelLabels[educationHistory.educationLevel]
                    : '--'}
                </Text>
              </Flex>
              <Flex direction="column" gap={2} basis={'40%'}>
                <Text variant="labelM">Education Country</Text>
                <Text>
                  {educationHistory.educationCountry
                    ? countries.find(country => country.Code === educationHistory.educationCountry)
                        ?.Name
                    : '--'}
                </Text>
              </Flex>
            </Flex>

            <Flex gap={8}>
              <Flex direction="column" gap={2} basis={{ xs: '50%', sm: 'calc(60% - 32px)' }}>
                <Text variant="labelM">Start Date</Text>
                <Text>
                  {educationHistory.startDate
                    ? format(
                        convertTimelessDateStrToLocalDate(educationHistory.startDate),
                        'yyyy-MM',
                      )
                    : '--'}
                </Text>
              </Flex>
              <Flex direction="column" gap={2} basis={{ xs: '50%', sm: '40%' }}>
                <Text variant="labelM">End Date</Text>
                <Text>
                  {educationHistory.endDate
                    ? format(convertTimelessDateStrToLocalDate(educationHistory.endDate), 'yyyy-MM')
                    : '--'}
                </Text>
              </Flex>
            </Flex>

            <Flex direction="column" gap={2}>
              <Text variant="labelM">Credential Gained</Text>
              <Text>{educationHistory.credentialReceived}</Text>
            </Flex>
          </Flex>
          <EducationHistoryDocumentsDialogForm
            application={props.application}
            document={props.document}
          />
        </Form>
      </Flex>
    </DocumentDialog>
  )
}

function EducationHistoryDocumentsDialogForm(props: IDocumentDialogForm) {
  const { getObservableFiles } = useApplicationFormContext()

  const { document } = useFieldValues(['document'])
  const setFieldValues = useSetFieldValues()

  return (
    <Field
      as={FileUploadField}
      allowedFileTypes={['.jpg', '.pdf', '.png', '.jpeg']}
      application={props.application}
      fileLimit={1}
      fileType={props.document.documentType}
      label={
        <Text variant="bodyS" contrast="mid">
          Add your document below, supported file formats: JPG, JPEG, PDF, PNG, max number of files:
          1 <Asterisk />
        </Text>
      }
      name={`document`}
      onRemove={(id: string) =>
        setFieldValues({ document: document.filter(file => file.id !== id) })
      }
      section={props.document.sectionReference || ''}
      showHistory={false}
      validate={value => {
        const observableFiles = getObservableFiles({
          fileType: props.document.documentType,
          sectionReference: props.document.sectionReference,
        })

        if (Object.keys(observableFiles).length > 1) {
          return `This field has a file limit of ${1}.`
        }

        if (!(Object.keys(observableFiles).length || value.length)) {
          return 'This field is required'
        }
        return true
      }}
    />
  )
}
