import {
  DatePicker,
  Flex,
  NumberInput,
  Select,
  Text,
  TextInput,
  createForm,
} from '@applyboard/crystal-ui'
import { HasLanguageProficiencyCodes, TestType } from 'applications-types-lib'
import { isEmpty } from 'lodash'
import { IAdditionalDocumentDialog, ILanguageTestDocumentDialogForm } from '../types'
import {
  Subscores,
  documentTypeOptions,
  getLanguageProficiencyFieldsInfo,
  getSubscoresOrder,
  validateSubscore,
} from '../../../../utils'
import { FileUploadField, FileUploadFieldValue } from '../../ApplicationForms/FileUploadField'
import { DocumentDialog } from '../DocumentDialog'
import { useEffect, useRef, useState } from 'react'
import { useUpdateRequestedDocuments } from '../../../../hooks'
import { nanoid } from 'nanoid'
import { useApplicationFormContext } from '../../ApplicationForms/ApplicationForm'
import { ChangeLanguageTestDialog } from '../../ChangeLanguageTestDialog'
import { Asterisk } from '../../../Asterisk'

type LanguageTestDocumentsFormFields = {
  testType: string
  documents: FileUploadFieldValue[]
  certificateNumber: string
  testDate: string
  overallScore: string
  listening: string
  reading: string
  writing: string
  speaking: string
}

const defaultValue = {
  testType: '',
  documents: [],
  testDate: '',
  certificateNumber: '',
  overallScore: '',
  listening: '',
  reading: '',
  speaking: '',
  writing: '',
}

const { Form, Field, useFieldValues, useSetFieldValues } =
  createForm<LanguageTestDocumentsFormFields>()

export function LanguageTestDocumentsDialog(props: IAdditionalDocumentDialog) {
  const [open, setOpen] = useState(false)
  const { pendingFileUploadState, resetFiles } = useApplicationFormContext()
  const { isUpdatingApplication, updateRequestedDocuments } = useUpdateRequestedDocuments({
    applicationId: props.application.id,
    index: props.index,
  })

  const languageTestId = useRef<string>()
  useEffect(() => {
    languageTestId.current = nanoid()
  }, [])

  useEffect(() => {
    resetFiles({})
  }, [resetFiles])

  const requestedDocuments = props.application.attributes?.requestedDocuments?.[props.id]
  if (!requestedDocuments) {
    return null
  }

  return (
    <DocumentDialog
      document={props.document}
      heading={`Add ${documentTypeOptions?.[props.document.documentType]?.label}`}
      loading={isUpdatingApplication}
      open={open}
      setOpen={open => {
        resetFiles({})
        setOpen(open)
      }}
      form={`language-test-documents-${languageTestId}`}
    >
      <Flex direction="column" gap={4}>
        <Text contrast="mid">{props.document.note}</Text>
        <Form
          defaultValues={defaultValue}
          validationMode={'onSubmit'}
          onSubmit={data => {
            updateRequestedDocuments({
              additionalData: {
                languageProficiency: {
                  hasLanguageProficiency: 'HAVE' as HasLanguageProficiencyCodes,
                  languageProficiencyData: {
                    [languageTestId.current as string]: {
                      certificateNumber: data.certificateNumber,
                      overallScore: data.overallScore,
                      testDate: data.testDate.substring(0, 10),
                      testType: data.testType as TestType,
                      subscores: {
                        listening: parseFloat(data.listening),
                        reading: parseFloat(data.reading),
                        speaking: parseFloat(data.speaking),
                        writing: parseFloat(data.writing),
                      },
                    },
                  },
                },
              },
              dataFiles: pendingFileUploadState,
              documentType: props.document.documentType,
              onSuccess: () => {
                setOpen(false)
              },
              requestedDocuments,
              requestedDocumentsId: props.id,
              sectionReference: languageTestId.current,
            })
          }}
          id={`language-test-documents-${languageTestId}`}
        >
          <LanguageTestDocumentsDialogForm
            languageTestId={languageTestId.current as string}
            application={props.application}
            document={props.document}
          />
        </Form>
      </Flex>
    </DocumentDialog>
  )
}

function LanguageTestDocumentsDialogForm(props: ILanguageTestDocumentDialogForm) {
  const { addPendingDelete, getObservableFiles } = useApplicationFormContext()

  const [dialogData, setDialogData] = useState<{
    currentTestValue: string
    isOpen: boolean
    newTestValue: string | null
  }>({
    currentTestValue: '',
    isOpen: false, // is the dialog open or closed
    newTestValue: null,
  })

  const {
    certificateNumber,
    documents,
    listening,
    overallScore,
    reading,
    speaking,
    testDate,
    testType,
    writing,
  } = useFieldValues([
    'certificateNumber',
    'documents',
    'listening',
    'overallScore',
    'reading',
    'speaking',
    'testDate',
    'testType',
    'writing',
  ])
  const setFieldValues = useSetFieldValues()

  const testTypeOptions = {
    IELTS: 'IELTS',
    PTE: 'PTE Academic',
    TOEFL: 'TOEFL®️',
  }

  const maxDate = new Date()
  const minDate = new Date()

  maxDate.setFullYear(maxDate.getFullYear() + 15)
  minDate.setFullYear(minDate.getFullYear() - 50)

  const fieldsInfo = getLanguageProficiencyFieldsInfo(testType)
  const subscoreOrder = getSubscoresOrder(testType)

  return (
    <Flex direction="column" gap={6}>
      <Flex.Item basis="100%">
        <Field
          as={Select}
          label="Test type"
          name={`testType`}
          appearance="styled"
          required="Test type is required"
          onChange={value => {
            if (
              testType.length !== 0 &&
              (!isEmpty(testDate) ||
                !isEmpty(certificateNumber) ||
                !isEmpty(documents) ||
                !isEmpty(overallScore) ||
                !isEmpty(listening) ||
                !isEmpty(reading) ||
                !isEmpty(speaking) ||
                !isEmpty(writing))
            ) {
              setDialogData({
                currentTestValue: testType,
                isOpen: true,
                newTestValue: (value as string | undefined) || null,
              })
            }
          }}
        >
          {Object.entries(testTypeOptions).map(([value, label]) => (
            <Select.Option value={value} label={label} key={value} />
          ))}
        </Field>
      </Flex.Item>
      {fieldsInfo && subscoreOrder ? (
        <>
          <Field
            as={FileUploadField}
            allowedFileTypes={['.jpg', '.pdf', '.png', '.jpeg']}
            application={props.application}
            fileLimit={1}
            fileType={fieldsInfo.fileType}
            label={
              <Text variant="bodyS" contrast="mid">
                Add your document below, supported file formats: JPG, JPEG, PDF, PNG, max number of
                files: 1 <Asterisk />
              </Text>
            }
            name={`documents`}
            onRemove={(id: string) =>
              setFieldValues({ documents: documents.filter(file => file.id !== id) })
            }
            section={props.languageTestId}
            showHistory={false}
            validate={value => {
              const fileLimit = 1
              if (!value.length) {
                return 'This field is required'
              }
              if (value.length > fileLimit) {
                return `This field has a file limit of ${fileLimit}.`
              }
              return true
            }}
          />
          <Flex basis="100%" gap={4} direction="row" wrap>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={DatePicker}
                label="Test date"
                name="testDate"
                maxDate={maxDate.toISOString()}
                minDate={minDate.toISOString()}
                required="Test date is required"
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput}
                label={fieldsInfo.codeLabel}
                name="certificateNumber"
                required={`${fieldsInfo.codeLabel} is required`}
              />
            </Flex.Item>
            <Flex.Item basis="calc(50% - 8px)">
              <Field
                as={NumberInput}
                label="Overall score"
                name="overallScore"
                max={fieldsInfo.maxOverall}
                min={fieldsInfo.minOverall}
                required="Overall score is required"
                validate={data => {
                  if (
                    parseInt(data) < fieldsInfo.minOverall ||
                    parseInt(data) > fieldsInfo.maxOverall
                  ) {
                    return 'Invalid score'
                  }

                  return true
                }}
              />
            </Flex.Item>
            <Flex.Item>{null}</Flex.Item>
          </Flex>
          <Flex basis="100%" gap={4} direction="row" wrap>
            {subscoreOrder.map(subscore => (
              <SubscoreField
                key={subscore.name}
                label={subscore.label}
                name={subscore.name}
                max={fieldsInfo.maxSubscore}
                min={fieldsInfo.minSubscore}
                validate={data => {
                  return validateSubscore(testType, parseInt(data))
                }}
              />
            ))}
          </Flex>
        </>
      ) : null}
      <ChangeLanguageTestDialog
        currentTestLabel={
          testTypeOptions[dialogData.currentTestValue as keyof typeof testTypeOptions]
        }
        isOpen={dialogData.isOpen}
        newTestLabel={
          dialogData.newTestValue
            ? testTypeOptions[dialogData.newTestValue as keyof typeof testTypeOptions]
            : ''
        }
        onCloseDialog={(isOpen: boolean) => {
          setFieldValues({
            testType: dialogData.currentTestValue,
          })

          setDialogData({
            currentTestValue: '',
            isOpen: isOpen,
            newTestValue: null,
          })
        }}
        onConfirm={() => {
          const fieldsInfo = getLanguageProficiencyFieldsInfo(dialogData.currentTestValue)
          Object.keys(
            getObservableFiles({
              fileType: fieldsInfo?.fileType,
              sectionReference: props.languageTestId,
            }),
          ).forEach(id => {
            addPendingDelete(id)
          })

          setFieldValues({
            ...defaultValue,
            testType: dialogData.newTestValue || '',
          })

          setDialogData({
            currentTestValue: '',
            isOpen: false,
            newTestValue: null,
          })
        }}
      />
    </Flex>
  )
}

type SubscoreFieldProps = {
  label: string
  name: Subscores
  min: number
  max: number
  validate: (value: string) => string | true
}

function SubscoreField(props: SubscoreFieldProps) {
  return (
    <Flex.Item basis="calc(50% - 8px)">
      <Field
        as={NumberInput}
        label={props.label}
        name={props.name}
        max={props.max}
        min={props.min}
        required={`${props.label} is required`}
        validate={props.validate}
      />
    </Flex.Item>
  )
}
