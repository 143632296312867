import { useAuthQuery } from '../auth'
import { ApiAgentRequest, request } from '../../utils/request'
import { Application, GetApplicationSearchQuery, RawApplicationsResponse } from './types'
import { convertToUrlSearchParams } from '../../utils'

export function useGetApplications(searchQuery: GetApplicationSearchQuery, options: { enabled: boolean }) {
  const { data, isLoading, isFetching } = useAuthQuery(
    ['app-list', searchQuery ],
    async () => {
      return await request<RawApplicationsResponse>(
        new ApiAgentRequest(`/applications?${convertToUrlSearchParams(searchQuery)}`, {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is RawApplicationsResponse => res,
        },
      )
    },
    {
      enabled: options?.enabled === undefined ? true : options.enabled,
    },
  )

  return {
    isLoadingApplications: isLoading,
    isFetchingApplications: isFetching,
    applications: data?.data || [],
    totalApplications: data?.meta?.count ?? 0
  }
}

export function getApplicantName(application: Application) {
  const applicant = application.attributes.personalInformation.basicPersonalInformation;
  const middleName = applicant.middleName ? ` ${applicant.middleName}` : '';
  const familyName = applicant.familyName ? ` ${applicant.familyName}` : '';

  return `${applicant.givenName}${middleName}${familyName}`
}
