/* eslint-disable */
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import { parseJsonIfPresent } from '../utils/parseJsonIfPresent'
import { isTrue } from '../utils/isTrue'

// any uses of these fake "vars" will be replaced with relevant strings or undefined by webpack during build time
declare var __CONFIG__NEWRELIC_ENABLED: string | undefined
declare var __CONFIG__NEWRELIC_CONFIG: string | undefined

const config = {
  newrelicEnabled: parseJsonIfPresent(__CONFIG__NEWRELIC_ENABLED),
  newrelicConfig: parseJsonIfPresent(__CONFIG__NEWRELIC_CONFIG),
}

let newrelicOptions

if (isTrue(config.newrelicEnabled) && config.newrelicConfig) {
  newrelicOptions = config.newrelicConfig
}

if (newrelicOptions) {
  // The agent loader code executes immediately on instantiation.
  new BrowserAgent(newrelicOptions)
}

// mock export to mark the file as module
export default {}
