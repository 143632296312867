import styled from '@emotion/styled'
import { Button, Flex, Text, Timeline, TimelineItemDotProps } from '@applyboard/crystal-ui'
import { TimelineItemDot } from './TimelineItemDot'
import { isTabCompleted } from './utils'
import { useQueryClient } from '@tanstack/react-query'
import { StudentApplication } from '../types'

type ApplicationTimelineProps = {
  selectedTab: number
  setSelectedTab: (tab: number) => void
  application: StudentApplication
}

export function ApplicationTimeline(props: ApplicationTimelineProps) {
  const queryClient = useQueryClient()
  const timelineItems = [
    { key: crypto.randomUUID(), label: 'Personal Information' },
    { key: crypto.randomUUID(), label: 'Contact Information' },
    { key: crypto.randomUUID(), label: 'Citizenship and Status' },
    { key: crypto.randomUUID(), label: 'Education History' },
    { key: crypto.randomUUID(), label: 'Language Proficiency' },
    { key: crypto.randomUUID(), label: 'Other Required Documents' },
  ]

  function getTimelineDotProps(index: number, isCurrentCompleted: boolean): TimelineItemDotProps {
    if (index === props.selectedTab - 1)
      return { intent: 'primary', icon: 'circle', variant: 'fill' }
    if (isCurrentCompleted) return { intent: 'positive', icon: 'check', variant: 'fill' }
    return { intent: 'secondary', icon: 'circle', variant: 'outline' }
  }

  const areTabsCompleted = timelineItems.map((_timelineItem, index) => {
    return isTabCompleted({
      index: index,
      application: props.application,
    })
  })

  return (
    <>
      {/** timeline has weird margin that is throwing flexbox off, undo with padding */}
      <Flex hideAbove="md" pl={{ xs: 8, sm: 16, md: 32 }} py={6} align="center" justify="center">
        <Timeline
          aria-label="Application Timeline"
          columnGap={{ xs: 8, sm: 16, md: 32 }}
          rowGap={{ xs: 8, sm: 16, md: 32 }}
          orientation="horizontal"
          value={props.selectedTab - 1}
        >
          {timelineItems.map((timelineItem, index) => {
            const isCurrentCompleted = areTabsCompleted[index]

            const { intent, icon, variant } = getTimelineDotProps(index, isCurrentCompleted)
            return (
              <Timeline.Item
                key={index}
                aria-label={`${timelineItem.label} ${
                  isCurrentCompleted ? 'is complete' : 'is incomplete'
                }`}
                intent={'secondary'}
              >
                <Timeline.Item.Dot intent={intent} icon={icon} variant={variant} />
              </Timeline.Item>
            )
          })}
        </Timeline>
      </Flex>
      <Flex hideBelow="md">
        <TimelineWrapper>
          {/* split desktop & mobile timeline, extract logic for selected or not */}
          <TimelineV1 aria-label="Application Timeline">
            {timelineItems.map((timelineItem, index) => {
              const isCurrentCompleted = areTabsCompleted[index]
              const isPreviousCompleted = index > 0 ? areTabsCompleted[index - 1] : true

              return (
                <TimelineItem
                  key={timelineItem.key}
                  aria-label={`${timelineItem.label} ${
                    isCurrentCompleted ? 'is complete' : 'is incomplete'
                  }`}
                >
                  <TimelineItemDotWrapper>
                    {index !== 0 && <TopLine />}
                    <TimelineItemDot
                      selectedTab={props.selectedTab}
                      index={index}
                      application={props.application}
                    />
                    {index < timelineItems.length - 1 && <BottomLine />}
                  </TimelineItemDotWrapper>

                  {index + 1 !== props.selectedTab &&
                  (isCurrentCompleted || isPreviousCompleted) ? (
                    <Button
                      emphasis="transparent"
                      onClick={() => {
                        queryClient.invalidateQueries({
                          queryKey: ['applications', props.application?.id],
                        })
                        props.setSelectedTab(index + 1)
                      }}
                    >
                      {timelineItem.label}
                    </Button>
                  ) : (
                    <Flex px={4} py={3}>
                      <Text variant="labelL">{timelineItem.label}</Text>
                    </Flex>
                  )}
                </TimelineItem>
              )
            })}
          </TimelineV1>
        </TimelineWrapper>
      </Flex>
    </>
  )
}

const borderWidth = '1px'
const iconHeightPx = '20px'
const columnGapPx = '8px'
const rowGapPx = '24px'

const TimelineWrapper = styled.div({
  height: 'fit-content',
})

const TimelineV1 = styled.ol({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  columnGap: columnGapPx,
  rowGap: rowGapPx,
  marginBlock: 0,
  paddingInline: 0,
})

const TimelineItem = styled.li({
  display: 'contents',
  textWrap: 'nowrap',
  'button > div': {
    justifyContent: 'flex-start',
  },
})

const TimelineItemDotWrapper = styled.div({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
})

const TopLine = styled.div(({ theme }) => {
  return {
    border: `${borderWidth} solid ${theme.colors.borderInactive}`,
    bottom: `calc(50% + (${iconHeightPx} / 2) + ${borderWidth})`,
    height: `calc(50% - (${iconHeightPx} / 2) + (${rowGapPx} / 2) - ${borderWidth})`,
    left: `calc(50% - ${borderWidth})`,
    position: 'absolute',
    width: 0,
  }
})

const BottomLine = styled.div(({ theme }) => {
  return {
    border: `1px solid ${theme.colors.borderInactive}`,
    height: `calc(50% - (${iconHeightPx} / 2) + (${rowGapPx} / 2) - ${borderWidth})`,
    left: `calc(50% - ${borderWidth})`,
    position: 'absolute',
    top: `calc(50% + (${iconHeightPx} / 2) + ${borderWidth})`,
    width: 0,
  }
})
