import { Checkbox, createForm, Flex, Select, TextInput } from '@applyboard/crystal-ui'
import { ContactInformation, RelationshipType as ContactRelation } from 'applications-types-lib'
import { isEmpty, omitBy, set } from 'lodash'
import { RawApplicationResponse, useUpdateApplication } from '../../../hooks'
import { emailValidate } from '../../../utils'
import { ApplicationFormCard } from './ApplicationFormCard'
import { RelationshipType } from '../../../utils/enums'
import { StudentApplication } from '../types'
import { ApplicationFormTitle } from './ApplicationFormTitle'

type ContactInformationFormFields = {
  phone: string
  alternatePhone: string
  email: string
  provideEmergencyContact: boolean
  emergencyName: string
  emergencyRelationship: string
  emergencyPhone: string
  emergencyEmail: string
}

const { Form, Field, useFieldValues } = createForm<ContactInformationFormFields>()

type ContactInformationTabProps = {
  disabled?: boolean
  application: StudentApplication
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: Error) => void
}

export function ContactInformationTab(props: Readonly<ContactInformationTabProps>) {
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.application.id,
  })

  return (
    <Flex grow={1} direction="column">
      <Form
        defaultValues={{
          phone:
            props.application?.attributes?.personalInformation?.contactInformation?.phoneNumber ||
            '',
          alternatePhone:
            props.application?.attributes?.personalInformation?.contactInformation
              ?.alternatePhoneNumber || '',
          email: props.application.attributes?.personalInformation?.contactInformation?.email || '',
          provideEmergencyContact: haveEmergencyContact(props.application),
          emergencyName:
            props.application?.attributes?.personalInformation?.emergencyContact?.name || '',
          emergencyRelationship:
            props.application?.attributes?.personalInformation?.emergencyContact?.relationship ||
            '',
          emergencyPhone:
            props.application?.attributes?.personalInformation?.emergencyContact?.phoneNumber || '',
          emergencyEmail:
            props.application?.attributes?.personalInformation?.emergencyContact?.email || '',
        }}
        onSubmit={data => {
          if (props.disabled) {
            props.onSuccess()
          } else {
            const contactInformation: Omit<ContactInformation, 'email'> = {
              phoneNumber: data.phone,
              alternatePhoneNumber: data.alternatePhone,
            }
            const personalInformation = {
              contactInformation,
            }

            if (data.provideEmergencyContact) {
              set(personalInformation, 'emergencyContact', {
                name: data.emergencyName,
                relationship: data.emergencyRelationship as ContactRelation,
                email: data.emergencyEmail,
                phoneNumber: data.emergencyPhone,
              })
            } else {
              set(personalInformation, 'emergencyContact', null)
            }

            updateApplication(
              {
                attributes: {
                  personalInformation,
                },
              },
              {
                onSuccess: props.onSuccess,
                onError: props.onError,
              },
            )
          }
        }}
      >
        <ApplicationFormCard
          cardNumber={2}
          icon="☎️"
          title="Contact Information"
          isLoading={isUpdatingApplication}
          disabled={props.disabled}
        >
          <ContactInformationFields disabled={props.disabled} application={props.application} />
        </ApplicationFormCard>
      </Form>
    </Flex>
  )
}

type ContactInformationFieldsProps = {
  disabled?: boolean
  application: StudentApplication
}

function ContactInformationFields(props: Readonly<ContactInformationFieldsProps>) {
  const { provideEmergencyContact, phone, emergencyPhone } = useFieldValues([
    'provideEmergencyContact',
    'phone',
    'emergencyPhone',
  ])

  return (
    <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field
          as={TextInput}
          label="Phone number"
          name="phone"
          required={
            !props.disabled
              ? phone.length
                ? 'Invalid phone number'
                : 'Phone number is required'
              : false
          }
          disabled={props.disabled}
        />
      </Flex.Item>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field
          as={TextInput}
          label="Alternate phone number"
          name="alternatePhone"
          disabled={props.disabled}
        />
      </Flex.Item>
      <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
        <Field as={TextInput} label="Email" name="email" disabled />
      </Flex.Item>
      {!props.disabled || haveEmergencyContact(props.application) ? (
        <Flex basis="100%" pt={{ xs: 6, sm: 0 }}>
          <ApplicationFormTitle
            variant="titleS"
            level={3}
            icon="🚨"
            title="Emergency Contact"
          />
        </Flex>
      ) : null}
      {!props.disabled ? (
        <Flex.Item basis={{ xs: '100%' }}>
          <Field
            as={Checkbox}
            label="Would you like to provide an emergency contact?"
            name="provideEmergencyContact"
          />
        </Flex.Item>
      ) : null}
      {provideEmergencyContact ? (
        <>
          <Flex.Item basis={{ xs: '100%' }}>
            <Field
              as={TextInput}
              label="Name"
              name="emergencyName"
              disabled={props.disabled}
              required={!props.disabled && provideEmergencyContact ? 'Name is required' : false}
            />
          </Flex.Item>
          <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
            {/* //TODO: This field becomes a Select and use RelationshipCode from Application. Update with Aurora lastest changes */}
            <Field
              as={Select}
              label="Relationship"
              name="emergencyRelationship"
              disabled={props.disabled}
              appearance="styled"
              required={
                !props.disabled && provideEmergencyContact ? 'Relationship is required' : false
              }
            >
              <Select.Option label="Parent" value={RelationshipType.PARENT} />
              <Select.Option label="Guardian" value={RelationshipType.GUARDIAN} />
              <Select.Option label="Friend" value={RelationshipType.FRIEND} />
              <Select.Option label="Spouse" value={RelationshipType.SPOUSE} />
              <Select.Option label="Relative" value={RelationshipType.RELATIVE} />
              <Select.Option label="Other" value={RelationshipType.OTHER} />
            </Field>
          </Flex.Item>
          <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
            <Field
              as={TextInput}
              label="Phone number"
              name="emergencyPhone"
              disabled={props.disabled}
              required={
                !props.disabled && provideEmergencyContact
                  ? emergencyPhone.length
                    ? 'Invalid phone number'
                    : 'Phone number is required'
                  : false
              }
            />
          </Flex.Item>
          <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
            <Field
              as={TextInput}
              label="Email"
              name="emergencyEmail"
              validate={(value: string) => {
                if (!!value && !emailValidate(value)) {
                  return 'This email is invalid.'
                }

                return true
              }}
              disabled={props.disabled}
              required={!props.disabled && provideEmergencyContact ? 'Email is required' : false}
            />
          </Flex.Item>
        </>
      ) : null}
    </Flex>
  )
}

function haveEmergencyContact(application: StudentApplication) {
  if (!application?.attributes?.personalInformation?.emergencyContact) {
    return false
  }

  return !isEmpty(
    omitBy(application?.attributes?.personalInformation?.emergencyContact, v => !v?.length),
  )
}
